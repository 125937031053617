<template>
  <div class="Receiving-manage bj">
    <div>
      <div class="seach order-wrapper" style="justify-content: space-between">
        <div class="title" style="padding-top: 0; padding-left: 0">
          <el-button
            type="primary"
            icon="el-icon-refresh"
            class="bjsearch"
            @click="Refresh"
          ></el-button>
        </div>
        <div class="d-flex" style="align-items: flex-start; flex-wrap: wrap">
          <div class="mr10">
            <el-date-picker
              @change="payChange"
              v-model="pay_at"
              :time-arrow-control="true"
              type="datetimerange"
              range-separator="—"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              start-placeholder="订单时间起"
              end-placeholder="订单时间止"
            >
            </el-date-picker>
          </div>
          <div class="inputs">
            <el-input
              placeholder="输入合并批次号"
              v-model="from.delivery_take_order_batch_no"
              clearable
            ></el-input>
          </div>
          <el-button type="primary" icon="el-icon-search" @click="search"
            >查询</el-button
          >
        </div>
      </div>
    </div>
    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        ref="multipleTable"
        tooltip-effect="dark"
        style="width: 100%"
      >
        >
        <el-table-column prop="print_status" align="center" label="合并批次号">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.delivery_take_order_batch_no) }}
          </template>
        </el-table-column>

        <el-table-column prop="order_at" align="center" label="订单时间起">
          <template slot-scope="scope">{{
            $empty.empty(tool.timestampToTime(scope.row.pay_at_start))
          }}</template>
        </el-table-column>
        <el-table-column prop="order_at" align="center" label="订单时间止">
          <template slot-scope="scope">
            {{ $empty.empty(tool.timestampToTime(scope.row.pay_at_end)) }}
          </template>
        </el-table-column>
        <el-table-column prop="print_status" align="center" label="打印状态">
          <template slot-scope="scope">
            <div v-if="role == 'delivery'">
              <el-tag type="success" v-if="scope.row.delivery_print_status == 2"
                >已打印</el-tag
              >
              <el-tag type="warning" v-else>未打印</el-tag>
            </div>
            <div v-else>
              <el-tag type="success" v-if="scope.row.tdc_print_status == 2"
                >已打印</el-tag
              >
              <el-tag type="warning" v-else>未打印</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="printlist(scope.row)"
              >打印</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { Loading } from "element-ui";
import { BASE } from "../../api/index";
export default {
  name: "Receiving",
  components: {},
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      role: sessionStorage.getItem("manageIsdentity"),
      pay_at: "",
      from: {
        pay_at_start: "",
        pay_at_end: "",
        delivery_take_order_batch_no: "",
        page: 1,
        pageSize: 10,
      },
      from1: {
        pay_at_start: "",
        pay_at_end: "",
        delivery_take_order_batch_no: "",
        page: 1,
        pageSize: 10,
      },
      value1: [],
      arrList: [],
      tableData: [],
      currentPage: 1,
      total: 0,
      multipleSelection: [],
      lsttables: [],
    };
  },
  created() {
    console.log(window.location.href);
    if (window.location.href.indexOf("?") > -1) {
      /** 历史丢参问题，修复 start */
      const url = this.tool.getUrl(window.location.href);
      // 无效的值
      const invalid = ["", null, undefined, "null", "undefined"];
      url.page = (invalid.includes(url.page) && 1) || url.page;
      url.pageSize = (invalid.includes(url.pageSize) && 10) || url.pageSize;
      /** 历史丢参问题，修复 end */
      this.from = url;
      this.from1 = url;
      this.pay_at = [this.from.pay_at_start, this.from.pay_at_end];
      this.currentPage = Number(this.from.page);
      this.from.pageSize = Number(this.from.pageSize);
    }
    this.hqlist();
  },
  methods: {
    payChange(val) {
      console.log(val);
      if (val) {
        // this.$set(this.from1.pay_at,'start_time',val[0])
        this.from.pay_at_start = val[0];
        this.from.pay_at_end = val[1];
      } else {
        this.from.pay_at_start = "";
        this.from.pay_at_end = "";
      }
    },
    printlist(el) {
      window.open(
        `${
          this.role == "delivery" ? BASE.PRO3 : BASE.PRO4
        }/divide/delivery/take/order/export` +
          `?delivery_take_order_batch_no=${
            el.delivery_take_order_batch_no
          }&token=${
            sessionStorage.getItem("token")
              ? sessionStorage.getItem("token")
              : ""
          }`
      );
      this.hqlist();
    },
    search() {
      this.from.page = 1;
      this.currentPage = 1;
      this.from1 = this.from;
      this.tool.getResult(this.from1, window.location.href);
      this.hqlist();
    },

    Refresh() {
      this.from = {
        pay_at_start: "",
        pay_at_end: "",
        delivery_take_order_batch_no: "",
        page: 1,
        pageSize: 10,
      };
      this.from1 = {
        pay_at_start: "",
        pay_at_end: "",
        delivery_take_order_batch_no: "",
        page: 1,
        pageSize: 10,
      };
      this.lsttables = [];
      this.value1 = [];
      this.currentPage = 1;
      this.tool.getResult(this.from1, window.location.href);
      this.pay_at = [this.from.pay_at_start, this.from.pay_at_end];
      this.hqlist();
    },
    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.tool.getResult(this.from1, window.location.href);
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.tool.getResult(this.from1, window.location.href);
      this.hqlist();
    },

    hqlist() {
      this.loading = true;
      let form = this.tool.DeepCopy({}, this.from);
      this.$api.delivery.goodslistti(form).then((res) => {
        console.log(res, "获取数据");

        this.tableData = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="scss">
.Receiving-manage {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;

  .order-wrapper {
    display: flex;
    align-items: flex-start;
    background: #ffffff;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 10px;

    .inputs {
      margin-right: 10px;
      margin-bottom: 10px;
    }

    .multiple-select {
      width: 250px;
    }
  }

  .title {
    background: #ffffff;
    padding: 0 15px 13px;
    box-sizing: border-box;
    display: flex;

    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      .inputs {
        width: 207px;
        margin-right: 8px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }

  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    box-sizing: border-box;

    .titlname {
      font-size: 17px;
      color: #21c2ac;
      margin-bottom: 25px;
    }

    .imgs {
      display: flex;

      .imglist {
        width: 165px;
        height: 123px;
        border: 1px dashed #a4adc5;
        padding: 14px 8px;
        text-align: center;
        border-radius: 7px;
        box-sizing: border-box;
        margin-right: 16px;

        .div {
          margin-top: 20px;
          padding-bottom: 20px;
        }

        // img{
        //     width: 100%;
        //     height: 100%;
        // }
      }
    }

    .disply {
      display: flex;
      margin-bottom: 22px;
    }

    .mr {
      margin-right: 31px;
    }

    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 77px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }

      .el-input {
        width: 302px;
      }
    }
  }
}

.colors {
  color: #fa884c;
}

.times {
  margin-right: 8px;
}
</style>
